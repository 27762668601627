<template>
    <div class="myOrders">
        <div class="myOrdersMain">
            <div class="myOrders-title">
                <div class="back" @click="backClick">
                    <img src="@/assets/images/personal/z.png" />
                    <span>Back</span>
                </div>
                <h5>My Orders</h5>
            </div>
            <div class="myOrders-main">
                <div class="main-nav">
                    <div>
                        <a @click="orderClick(0, 'all')" :class="orderType == 0 ? 'ative' : ''">All Orders</a>
                        <a @click="orderClick(1, 'waitpay')" :class="orderType == 1 ? 'ative' : ''">Unpaid</a>
                        <a @click="orderClick(2, 'waitsend')" :class="orderType == 2 ? 'ative' : ''">Processing</a>
                        <a @click="orderClick(3, 'waitconfirm')" :class="orderType == 3 ? 'ative' : ''">Shipped</a>
                        <!-- <a @click="orderClick(4)" :class="orderType == 4 ? 'ative' : ''">Review</a> -->
                        <a @click="orderClick(5, 'returns')" :class="orderType == 5 ? 'ative' : ''">Return</a>
                        <!-- <a @click="orderClick(6, '')" :class="orderType == 6 ? 'ative' : ''">Deleted History</a> -->
                    </div>
                    <div>
                        <img src="@/assets/images/personal/search.png" />
                    </div>
                </div>
                <div class="main-orders">
                    <div class="orders-title">
                        <div class="flexRroducts">
                            <span>Products</span>
                        </div>
                        <div class="flexPrice">
                            <span>Price</span>
                        </div>
                        <div class="flexStatus">
                            <span>Status</span>
                        </div>
                        <!-- <div class="flexOperating">
                            <span>Operating</span>
                        </div> -->
                    </div>
                    <div class="orders-list" v-loading="loading">
                        <div class="item" v-for="(item, index) in myOrder" :key="index">
                            <div class="item-title">
                                <div>
                                    <span>{{item.create_time}}</span>
                                    <p>Order NO.{{item.order_no}}</p>
                                </div>
                                <div>
                                    <img src="@/assets/images/personal/delet.png" />
                                </div>
                            </div>
                            <div class="item-main">
                                <div class="flexRroducts">
                                    <div class="order" v-for="(item1, index1) in item.order_goods" :key="index1">
                                        <div class="img"><img :src="item1.sku_image" /></div>
                                        <span>{{item1.goods_name}}</span>
                                        <p>x{{item1.num}}</p>
                                    </div>
                                </div>
                                <div class="flexPrice">
                                    <div class="order" v-for="(item1, index1) in item.order_goods" :key="index1">
                                        <span>{{strCurXSoyego}}{{item1.goods_money}}</span>
                                    </div>
                                </div>
                                <!-- 待支付 -->
                                <div class="flexStatus" v-if="item.order_status == 0">
                                    <span class="red">Waiting for Payment</span> 
                                    <!-- <p>23:59:59</p> -->
                                    <router-link :to="{ path: '/personal/myOrders/0', query: { data: item } }">Order Detalis<img src="@/assets/images/personal/r.png" /></router-link>
                                </div>
                                <!-- 待发货 -->
                                <div class="flexStatus" v-if="item.order_status == 1">
                                    <span class="yellow">Processing</span>
                                    <router-link :to="{ path: '/personal/myOrders/1', query: { data: item } }">Order Detalis<img src="@/assets/images/personal/r.png" /></router-link>
                                </div>
                                <!-- 待收货 -->
                                <div class="flexStatus" v-if="item.order_status > 2 && item.order_status < 8">
                                    <span class="green">Shipped</span>
                                    <router-link :to="{ path: '/personal/myOrders/2', query: { data: item } }">Order Detalis<img src="@/assets/images/personal/r.png" /></router-link>
                                </div>
                                <!-- 已完成 -->
                                <div class="flexStatus" v-if="item.order_status == 8">
                                    <span class="grey">Completed</span>
                                    <router-link :to="{ path: '/personal/myOrders/3', query: { data: item } }">Order Detalis<img src="@/assets/images/personal/r.png" /></router-link>
                                </div>
                                <!-- 已关闭 -->
                                <div class="flexStatus" v-if="item.order_status == -1">
                                    <span class="back">Canceled</span>
                                    <router-link :to="{ path: '/personal/myOrders/4', query: { data: item } }">Order Detalis<img src="@/assets/images/personal/r.png" /></router-link>
                                </div>
                                <!-- <div class="flexOperating" v-if="item.order_status == 0">
                                    <button class="backBtn">Pay</button>
                                    <button class="greyBtn">Cancel order</button>
                                </div>
                                <div class="flexOperating" v-if="item.order_status == 1">
                                    <button class="greyBtn">Support</button>
                                </div>
                                <div class="flexOperating" v-if="item.order_status > 2 && item.order_status < 8">
                                    <button class="backBtn">Confirm Recei</button>
                                    <button class="borderBtn">Logistics</button>
                                </div>
                                <div class="flexOperating" v-if="item.order_status == 8">
                                    <button class="borderBtn">Repurchase</button>
                                </div>
                                <div class="flexOperating" v-if="item.order_status == -1">
                                    <button class="borderBtn">Repurchase</button>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <!-- <el-pagination background layout="prev, pager, next" :total="1000"></el-pagination> -->
                    <el-pagination style="margin-top: 20px;" @current-change="handleCurrentChange" :current-page="currentPage"
                        :page-size="pageSize" layout="prev, pager, next" :total="total" background>
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.about-content {
    background: #f7f7f7;
    padding-top: 0;
}
</style>
<style lang="scss" scoped>
@import "./css/myOrders.scss";
</style>

<script>
import myOrders from "./js/myOrders.js"

export default {
    name: "myOrders",
    components: {
        
    },
    mixins: [myOrders]
}
</script>
