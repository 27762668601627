import { apiOrderList } from "@/api/order/order"

export default {
    name: "myOrders",
    components: {},
    data: () => {
        return {
            myOrder: [],
            currentPage: 1,
            pageSize: 10,
            total: 0,
            strCurXSoyego: localStorage.getItem("strCurXSoyego"),
            loading: true,
            orderType: 0,
            orderStatus: "all",
        }
    },
    created() {
        if(this.$route.query) {
            if(this.$route.query.type != undefined){
                this.orderType = this.$route.query.type;
                switch(this.orderType) {
                    case "0" : this.orderStatus = "all";break;
                    case "1" : this.orderStatus = "waitpay";break;
                    case "2" : this.orderStatus = "waitsend";break;
                    case "3" : this.orderStatus = "waitconfirm";break;
                    // case "4" : this.orderStatus = "all";break;
                    case "5" : this.orderStatus = "returns";break;
                    // case "6" : this.orderStatus = "all";break;
                    default : break;
                }
            }
        }
        this.getOrderList();
    },
    mounted() {
        
    },
    methods: {
        backClick() {
            this.$router.go(-1)
        },
        orderClick(type, status) {
            this.orderType = type;
            this.orderStatus = status;
            this.loading = true;
            this.getOrderList();
        },
        getOrderList() {
            apiOrderList({
                page: this.currentPage,
                page_size: this.pageSize,
                order_status: this.orderStatus,
                barterRate: "USD"
            }).then( res => {
                if(res.code == 0 && res.data){
                    console.log(res.data.list)
                    // console.log(new Date(res.data.list[0].create_time))
                    // let resData = Array.from(res.data.list);
                    
                    // res.data.list.forEach((item, index) => {
                    //     this.myOrder[index] = item;
                    //     this.myOrder[index].create_time = this.getTime(item.create_time)
                    // });
                    this.myOrder = res.data.list;
                    this.total = res.data.count;
                    this.loading = false;
                }
            }).catch( err => {
                // console.log(err)
            })
        },
        getTime(data) {
            let time = new Date(data * 1000);
            // time.setTime(data * 1000); //php返回的时间戳只有10位要* 1000
            return time.getFullYear() + '-' + (time.getMonth() + 1) + '-' + time.getDate();
        },
        handleCurrentChange(val) {
            this.loading = true;
            this.currentPage = val;
            this.getOrderList();
        },
    }
}